// Mixin für JPG-Bild/Icon mit Retinadarstellung
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include image-jpg('logo', 140px, 40px);
@mixin image-png($image, $width, $height) {
  background-image: url("#{$image-path}/#{$image}.png");
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  display: inline-block;
  @include retina-image-png($image, 100%, auto);
}

// Mixin für Retinadarstellung für PNG-Bild/Icon
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include retina-image-png('logo', 140px, 40px);
@mixin retina-image-png($image, $width, $height) {
  @media #{$retina} {
    background-image: url("#{$retina-image-path}/#{$image}_#{$retina-image-suffix}.png");
    background-size: $width $height;
  }
}
