// Color
$primary-color: #e4560b;
$white: #ffffff;

// Image
$image-path: "../images";
$retina-image-path: "../images/2x";

/* Retina */
// @media #{$retina} { ... }
$retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 2dppx)";
$retina-image-suffix: "2x";

// Breakpoint
$xmedium: "only screen and (min-width: 860px)";
